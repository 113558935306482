/* ==========================================================================
   1. GENERAL & GLOBAL STYLES
   ========================================================================== */

/* Reset margins and apply global font, box-sizing, etc. */
body, .App {
  margin: 0;
  padding: 0 16px;
  background-color: #121212; /* or #000, or any dark color */
  color: #ffffff;            /* set default text color to white */
  max-width: 1200px;
  margin: 0 auto;
  text-align: left;
}

:root {
  font-family: Inter, sans-serif;
  font-feature-settings: 'liga' 1, 'calt' 1; /* fix for Chrome */
}
@supports (font-variation-settings: normal) {
  :root { font-family: InterVariable, sans-serif; }
}

* {
  box-sizing: border-box;
}

/* ==========================================================================
   2. THEME CLASSES & BODY VARIANTS
   ========================================================================== */

/* Theme-specific styling for dark and light modes */
body.dark, .App.dark {
  background-color: #121212;
  color: #ffffff;
}

body.light, .App.light {
  background-color: #f8f9fa;
  color: #212529;
}

.dark {
  background-color: #121212;
  color: #ffffff;
}

.light {
  background-color: #f8f9fa;
  color: #212529;
}

/* ==========================================================================
   3. TYPOGRAPHY & LINKS
   ========================================================================== */

/* Link styling */
a,
a:visited {
  color: inherit;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  border-radius: 6px;
}


/* Section margin */
.section {
  margin-top: 4rem;
}

/* Basic styling for sub-headers throughout the site */
h2 {
  font-size: 1.5rem;
  color: inherit; /* keep it white or set an accent color */
}

/* ==========================================================================
   4. NAVIGATION
   ========================================================================== */

/* Nav container */
nav {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* keep everything left-aligned */
  width: 100%;
}

/* Nav-left content */
.nav-left {
  text-align: left;
  width: 100%;
}

.nav-left h1, 
.nav-left p {
  color: inherit;
}

.nav-left h1 {
  font-size: 3rem;
  margin-bottom: 0.5rem;
}

.nav-left p {
  font-size: 1.5rem;
  margin-top: 0;
}

/* Nav-right links */
.nav-right ul {
  list-style-type: none;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-top: 10px; /* give some breathing room */
  color: inherit;
}

.nav-right ul li {
  margin: 0 10px;
}

.nav-right ul li a {
  color: #ffffff;        /* Make links white (or any accent color) */
  font-size: 1rem;
  text-decoration: underline;
}

/* Social icons container */
.social-icons {
  margin-top: 20px;
}

.profile-pic {
  width: 120px;         /* adjust as desired */
  height: 120px;        /* adjust as desired */
  border-radius: 50%;   /* makes the image round */
  object-fit: cover;    /* ensures the image fills its container */
  margin-bottom: 1rem;  /* optional spacing */
}


/* ==========================================================================
   5. FOOTER
   ========================================================================== */

/* Footer container */
.footer {
  width: 100%;
  height: 60px; /* keep it short */
  padding: 0 20px; 
  position: relative;
  display: flex;
  align-items: center;
  background-color: inherit; 
  color: inherit;
}

/* Footer navigation */
.footer-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  color: inherit;
}

.footer-nav ul {
  list-style-type: none;
  display: flex;
  gap: 15px;
  margin: 0;
  padding: 0;
}

/* Footer navigation links */
.footer-nav ul li a {
  font-size: 1rem;
  text-decoration: none;
  color: inherit;
  position: relative; /* Required for positioning the pseudo-element */
  transition: color 0.2s ease-in-out; /* Optional: Smooth color transition */
}

/* Pseudo-element for the underline effect */
.footer-nav ul li a::after {
  content: ''; /* Empty content for the underline */
  position: absolute;
  width: 100%; /* Full width of the link */
  height: 2px; /* Thickness of the underline */
  bottom: -4px; /* Position it below the text */
  left: 0;
  background-color: #ffffff; /* Color of the underline (white for dark theme) */
  transform: scaleX(0); /* Start with no width */
  transform-origin: center; /* Scale from the center */
  transition: transform 0.3s ease-in-out; /* Smooth scaling animation */
}

/* Hover effect */
.footer-nav ul li a:hover::after {
  transform: scaleX(1); /* Expand to full width */
}

/* Optional: Adjust for light theme */
body.light .footer-nav ul li a::after {
  background-color: #212529; /* Darker color for light theme */
}


/* Footer icons */
.footer-icons {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.footer-icons a {
  text-decoration: none;
  color: inherit;
  margin-left: 10px;
}

.footer-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px; 
  font-weight: bold;
}

.footer-right ul {
  display: flex;
  gap: 15px;
  margin: 0;
  padding: 0;
}

/* ==========================================================================
   6. THEME TOGGLE BUTTON
   ========================================================================== */

   .theme-toggle {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: inherit;
  }
  
  .theme-toggle:hover {
    opacity: 0.8;
  }

  .theme-toggle svg {
    font-size: 1rem; /* Adjust the size as needed */
  }

/* ==========================================================================
   7. PROJECTS SECTION
   ========================================================================== */

/* Projects Section Styling */
.projects-section {
  color: inherit;     
}

.projects-section h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
  color: inherit;
}

.projects-section h2 {
  font-size: 1.5rem;
  margin: 0.5rem 0;
  color: inherit;
}

.project-item {
  padding: 1rem; /* padding inside the box */
  border-radius: 8px; /* smooth corners */
  transition: all 0.3s ease;
}

.project-item:hover {
  background-color: rgba(255, 255, 255, 0.05); /* subtle highlight for dark theme */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);   /* give it depth */
  transform: translateY(-2px); /* slight lift effect */
}


/* ==========================================================================
   8. BLOG SECTION
   ========================================================================== */

/* Blog container */
.blog-container {
  display: flex;
  min-height: 40vh;
  padding: 20px;
}

/* Blog content */
.blog-section {
  width: 40%;
  max-width: 600px;
  margin: auto;
  text-align: left;
}

.blog-list {
  list-style-type: none;
  padding: 0;
}

.blog-container h2 {
  font-size: 1.8rem;
  color: #ffffff;
}

.blog-container p {
  font-size: 1.1rem;
  line-height: 1.5;
  color: #ffffff;
}

.blog-item {
  margin: 10px 0;
}

.blog-link {
  text-decoration: none;
  font-size: 1.2rem;
  color: #ffffff; /* link color on dark background */
  transition: color 0.3s;
}

.blog-link:hover {
  color: #007bff; /* or any accent color */
}

/* ==========================================================================
   9. SINGLE BLOG POST
   ========================================================================== */

.blog-post-container {
  padding: 20px;
  text-align: left;
}

.blog-post-container h4 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #ffffff;
}

.blog-post-container p {
  font-size: 1.2rem;
  color: #ffffff;
}

/* ==========================================================================
   10. RESUME SECTION
   ========================================================================== */


/* Ensure the container inherits the theme's background and text color */
.resume-section.dark {
  background-color: #121212;
  color: #ffffff;
}

.resume-section.light {
  background-color: #f8f9fa;
  color: #212529;
}

/* Style the PDF container */
.resume-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the PDF horizontally */
  background: inherit; /* Inherit the background from the parent */
  padding: 10px;
}

/* Style the navigation buttons */
.resume-navigation {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.resume-navigation button {
  background-color: rgba(255, 255, 255, 0.1); /* Subtle background for buttons */
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  color: inherit; /* Inherit text color from theme */
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.resume-navigation button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.resume-navigation button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Style the PDF page canvas to match the theme */
.react-pdf__Page {
  background-color: #ffffff !important; /* Ensure the PDF page itself remains white for readability */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); /* Add a subtle shadow for depth */
}

/* Ensure the text layer inherits the theme's text color */
.react-pdf__Page__textLayer {
  color: #000000 !important; /* Keep text black for readability on the white PDF background */
}

/* ==========================================================================
   11. PICTURE CONTAINER
   ========================================================================== */
   .pictures-container h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
    color: inherit;
  }